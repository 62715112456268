<template>
    <a-card>
        <a-row>
            <a-col :span="5">
                <a-row style="margin-top: 5px"><a-col :span="2"><a-icon type="share-alt" /></a-col>
                    <a-col :span="7">{{l('organization')}}</a-col>
                 <a-col :span="15"><a-icon type="reload" @click="getTree" style="float: right"/></a-col>
                </a-row>
				
                <a-row>
					<div style="width:100%;overflow:auto;" :style="styObj">
						<div class="con" style="width: 100%;min-width: 180px;">
							<a-spin :spinning="isTreeLoading">
							<a-tree
									style="width:100%"
									:expanded-keys="expandedTreeKeys"
									:selected-keys="selectedTreeKeys"
									v-model="checkedTreeKeys"
									:tree-data="treeData"
									:replace-fields="treeReplaceFields"
									@select="onTreeSelect"
									@expand="onTreeExpand"
							/>
							</a-spin>
						</div>
					</div>
                </a-row>
            </a-col>

            <a-col :span="19" style="padding-left: 10px;">
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="10">
                        <a-button type="primary" @click="create('create')" v-if="isGranted('audit_layoutUser_create')">
                            <a-icon type="plus"/>
                            {{l('Create')}}
                        </a-button>
                        <a-button type="danger" @click="delete1" v-if="isGranted('audit_layoutUser_delete')">
                            <a-icon type="delete"/>
                            {{l('Delete')}}
                        </a-button>
                        <a-button type="primary" @click="Import" v-if="isGranted('audit_layoutUser_import')">
                            <a-icon type="import" />
                            {{l('import')}}
                        </a-button>
                    </a-col>

                    <a-col :span="14" class="d-flex justify-content-end">
                        <a-input-search :placeholder="l('please_enter_your_username_to_search')" v-model.trim="filterText" enterButton
                                        style="width: 200px;margin-left: 10px;" @search="filteronchange"

                        ></a-input-search>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns"
                             size="small"
                             :data-source="tableData"
                             :customRow="customRow"
                             :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'20px' }"
                             :rowKey="(record)=>record.id"
                             :pagination="false"
                    >
                        <!--<span slot="name" slot-scope="text,record" >
							 	<span @click="orderby">{{text}}</span>
							  </span>-->
                        <span slot="isResponsible" slot-scope="text">
							 	<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
							 	<span v-else><a-icon type='close'/></span>
							  </span>
                        <span slot="isAuditor" slot-scope="text">
							 	<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
							 	<span v-else><a-icon type='close'/></span>
							  </span>
                        <span slot="action" slot-scope="text,record">
									 <a @click.stop="createOrEditM(record)" v-if="isGranted('audit_layoutUser_edit')"><a-icon type="edit"/>{{l('Edit')}}</a>
									 <font v-if="isGranted('audit_layoutUser_delete')&&isGranted('audit_layoutUser_edit')">  |  </font>
									 <a @click.stop="delete2(record)" v-if="isGranted('audit_layoutUser_delete')"><a-icon type="delete"/>{{l('Delete')}}</a>
								 </span>
                    </a-table>
                </a-spin>
                <a-config-provider :locale="zh_CN">
                <a-pagination
                        class="pagination"
                        v-model="pageNumber"
                        :total="totalItems"
                        showSizeChanger
                        :defaultPageSize="request.maxResultCount"
                        :pageSizeOptions="pageSizeOptions"
                        :show-total="total => `共有 ${totalItems} 条`"
                        @change="onPageChange"
                        @showSizeChange="showPageSizeChange"
                />
                </a-config-provider>

            </a-col>
        </a-row>
    </a-card>
</template>




<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import AppComponentBase from '../../../shared/component-base/app-component-base.js';
    import {ModalHelper} from "../../../shared/helpers/modal";
    import {LayoutUserServiceProxy,LayoutOrgServiceProxy} from "../../../shared/service-proxies";
    import creat from "../layoutUser/creat-or-edit-layoutuser/creatoredit-layoutuser.vue";
    import ImportExcelModel from './import-excel-model/import-excel-model';

    let _this;
    export default{
        name: "layoutUser",
        mixins: [AppComponentBase],
        components:{},

        created() {
            _this = this;
            this._LayoutUserServiceProxy=new LayoutUserServiceProxy(this.$apiUrl, this.$api);
            this._LayoutOrgServiceProxy=new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
        },
        mounted(){
            this.getTree();
            this.getData();
			
			window.addEventListener('resize', this.changeHeight);
			this.changeHeight();
        },

        data(){
            return{
                zh_CN,
                tableData: [],
                treeData:[],
                treeReplaceFields: {
                    title: 'levelNameAndName',
                    key: 'id',
                },
                checkedTreeKeys: [],
                expandedTreeKeys:[],
                selectedTreeKeys:[],
                isTreeLoading:false,


                isLoading:false,
                isEdit: false,
                filterText: undefined,


                columns: [
                    {title: this.l('organization'),width: 110,dataIndex: 'orgName',align: 'center',},
                    {title: this.l('Users'),width: 65,dataIndex: 'realName',align: 'center',sorter: (a, b) => a.realName.length - b.realName.length,sortDirections: ['descend', 'ascend'],scopedSlots: { customRender: 'name' },},
                    {title: this.l('layoutuser.isresponsible'), width: 65, dataIndex: 'isResponsible', align: 'center',scopedSlots: {customRender: "isResponsible"}},
                    {title: this.l('layoutuser.isauditor'),width: 60,dataIndex: 'isAuditor',align: 'center',scopedSlots: {customRender: "isAuditor"}},
                    {title: this.l('Actions'),width: 150,dataIndex: 'action',align: 'center',fixed: 'right',scopedSlots: {customRender: "action"}},
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRowList: [],
                selectedRow: [],//选择的整行数据
				
				styObj:{
					height:0
				},

            }
        },

        methods:{

            //导入
            Import() {
                ModalHelper.create(
                    ImportExcelModel,
                    {
                    },
                    {
                        width: "800px"
                    }
                ).subscribe(res => {
                    this.getData();
                });
            },
			//div高度
			changeHeight(){
					this.styObj.height=window.innerHeight-199+'px';
			},
            //获取数据
            getTree() {
                this.treeData = [];
                this.isTreeLoading = true;
                this._LayoutOrgServiceProxy.getAllData(
                ).finally(() => {
                    this.isTreeLoading = false;
                }).then(rel => {
                    // rel = [];
                    console.log(rel,"树数据");
                    if(rel.length <= 0){
                        this.treeData = [{levelNameAndName: this.l('Please_create_an_organization'),  id: "0", children: rel,}];
                    }
                    else{
                        this.treeData = [];
                        rel.forEach(item=>{
                            this.treeData.push(item)
                        });
                    }
					// this.expandedTreeKeys.push('null');
					this.recursion(rel);
                })
            },

			recursion(rel) {
				console.log(rel);
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            console.log(rel[i].id)
						console.log(rel[i].children);
			            this.expandedTreeKeys.push(rel[i].id)
			            // this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
            onTreeExpand(expandedKeys, record) {
                console.log(expandedKeys,"expandedKeys");
                console.log(record,"record");
                this.expandedTreeKeys = expandedKeys;
            },
            //架构选择
            onTreeSelect(selectedKeys, info) {
                console.log(selectedKeys, info);
                if (selectedKeys.length > 0) {
                    this.selectedTreeKeys = [];
                    if(isNaN(selectedKeys[0])){
                        this.selectedTreeKeys = selectedKeys;

                        this.getData();
                    }
                    else{
                        this.selected = selectedKeys[0];
                        this.selectedTreeKeys = selectedKeys;

                        this.getData();

                    }

                }
            },

            //获取数据
            getData() {
				this.selectedRowKey = [];
				this.selectedRowList=[];
                this.isLoading = true;
                this._LayoutUserServiceProxy.getPaged(
                    this.selectedTreeKeys.length>0?this.selectedTreeKeys[0]:undefined,
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items;

                    this.totalItems = res.totalCount;
                    /*this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];*/
                })
            },


            orderby(){
                this.filterText="name";
                this.getData();
            },

            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKeys.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKeys.push(item.id);
                            this.selectedRowList.push(item);
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKeys = _.difference(_this.selectedRowKeys, [item.id]);
                        this.selectedRowList = _.difference(_this.selectedRowList, [item]);
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                //debugger;
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKey.push(record.id);
                    this.selectedRowList.push(record);

                } else {
                    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.selectedRowList = _.difference(_this.selectedRowList, [record]);
                }
            },
            //搜索框点击事件
            filteronchange() {
                //this.pageNumber = 1;
                //this.request.skipCount = 0;
                this.getData();
            },

            //新增
            create() {
                ModalHelper.create(creat, {
                    isEdit: false,
                    id: undefined,
                    Orgid: this.selected,
                },{width:600}).subscribe(res => {
                    if(res){
                    	this.getData();
                    }
                    this.selectedRowKey = [];
                    this.selectedRowList=[];
                })
            },
            createOrEditM(record){
                ModalHelper.create(creat, {
                    isEdit: true,
                    id: record.id,
                },{width:600}).subscribe(res => {
                    if(res){
                    	this.getData();
                    }
                    this.selectedRowKey = [];
                    this.selectedRowList=[];
                })
            },

            delete2(record) {
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this._LayoutUserServiceProxy.delete(record.id).then(res => {
                                this.getData();
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
									
                                });
                            })
                        }
                    })
            },


            delete1(){
                if (this.selectedRowKey <= 0 || this.selectedRowKey[0] == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }

                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this._LayoutUserServiceProxy.batchDelete(this.selectedRowKey).then(res => {
                                this.getData();
                                this.$notification["success"]({
                                   message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },

        }


    }



</script>



<style scoped lang="less">
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
	
	.con {
	    padding-bottom: 10px;
	}
</style>
